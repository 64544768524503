<template>
  <div class="container-fluid px-lg-5 mb-3 margin-top-0421">
    <div class="row">
      <div class="col-md-6 offset-md-3">
        <h1>Partner set up password for the first time</h1>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6 offset-md-3">
        <ErrorFormField :msg-type="$data.result.status" :msg-array="$data.notify.common" />
        <div class="mb-3">
          <label for="password" class="form-label">Password</label>
          <input type="password" class="form-control" id="password" placeholder="Password" />
        </div>
        <ErrorFormField :msg-type="$data.result.status" :msg-array="$data.notify.password" />
        <div class="mb-3">
          <label for="password" class="form-label">Confirm Password</label>
          <input
            type="password"
            class="form-control"
            id="confirmPassword"
            placeholder="Confirm Password"
          />
        </div>
        <ErrorFormField :msg-type="$data.result.status" :msg-array="$data.notify.confirmPassword" />
        <div class="mt-3">
          <LoadingIcons v-if="$data.ajax.isSubmitting" />
          <button
            class="btn btn-primary w-100"
            id="btnLoginModal"
            @click="btnSubmitClick"
            type="submit"
            v-else
          >
            Save
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LoadingIcons from '../../common/LoadingIcons.vue';
import ErrorFormField from '../../common/error/ErrorFormField.vue';
import Cookies from 'js-cookie';

export default {
  name: 'ResetPassword',
  components: { LoadingIcons, ErrorFormField },

  data: function () {
    return {
      // qa: [],
      token: '',
      ajax: {
        isSubmitting: false,
      },
      result: {
        status: '',
      },
      notify: {
        common: [],
        password: [],
        confirmPassword: [],
      },
    };
  },
  created() {
    this.$data.token = this.$route.query.token;
  },
  methods: {
    btnSubmitClick: async function () {
      let self = this;
      self.$data.notify.common = [];
      self.$data.notify.password = [];
      self.$data.notify.confirmPassword = [];
      let token = this.$data.token;
      this.$data.ajax.isSubmitting = true;

      let apiUrl = process.env.VUE_APP_API_URL;
      let password = document.getElementById('password').value;
      let confirmPassword = document.getElementById('confirmPassword').value;
      await fetch(apiUrl + 'partner/init-password', {
        method: 'POST',
        headers: {
          // 'Accept': 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded', // application/json application/x-www-form-urlencoded multipart/form-data; boundary=something
        },
        body:
          'token=' +
          encodeURI(token) +
          '&password=' +
          encodeURI(password) +
          '&confirm_password=' +
          encodeURI(confirmPassword),
      })
        .then((response) => response.json())
        .then((resData) => {
          let messages = resData.messages;
          if (messages.common.length > 0) self.$data.notify.common = messages.common;
          else self.$data.notify.common = [];
          self.$data.result.status = resData.status;
          if (resData.status == 'error') {
            if (messages.password.length > 0) self.$data.notify.password = messages.password;
            if (messages.confirmPassword.length > 0)
              self.$data.notify.confirmPassword = messages.confirmPassword;
            self.$data.ajax.isSubmitting = false;
            return false;
          }
          Cookies.set('name', resData.name);
          Cookies.set('token', resData.token);
          Cookies.set('role', resData.role);
          Cookies.set('status', resData.partner_status);
          self.$store.commit('partnerLoginUpdate', resData);
          self.$router.push('/partner/signup-agency');
          // self.$store.dispatch('loginUpdate', resData)
          self.$data.ajax.isSubmitting = false;
        })
        .catch(function (err) {
          console.log('error', err);
          self.$data.ajax.isSubmitting = false;
        });
    },
  },
  mounted() {
    if (this.$store.state.active == 1) {
      this.$router.push('/');
      return;
    }
  },
};
</script>

<style scoped></style>
